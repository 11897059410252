export const formatNumber = (num) => {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 1,
    useGrouping: true,
  });
};

export const getNumProPdfsUsed = (user) => {
  if (user && 'pro_pdf_count' in user) {
    return user['pro_pdf_count']
  }
  return 0
}
