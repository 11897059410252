import React from "react";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import PlanCards from "./PlanCards";

class AccountModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal.Header closeButton>
          <Container>
            <Row>
              <Col>
                <div>
                  <span style={{ fontSize: "175%" }}>
                    {this.props.user ? this.props.user.name : ""}
                  </span>
                  <span style={{ marginLeft: "15px" }}>
                    (<a href="#" onClick={this.props.logout}>logout</a>)
                  </span>
                </div>
                <small>{this.props.user ? this.props.user.email : ""}</small>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PlanCards
              user={this.props.user}
              uiConfig={this.props.uiConfig}
              firebaseAuth={this.props.firebaseAuth}
              verifyingLogin={this.props.verifyingLogin}
            />
          </Container>
        </Modal.Body>
      </div>
    );
  }
}

export default AccountModal;
