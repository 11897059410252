import React from "react";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import PlanCards from "./PlanCards";
import { formatNumber } from "./utils";

class UpgradeModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // If the user is not logged in, prompt them to login.

    let maxPlanPages = process.env.REACT_APP_FREE_MAX_PAGES * 1;
    let maxPlanTokens = process.env.REACT_APP_FREE_MAX_TOKENS * 1;
    let plan = 'free'
    let planCards = (
      <PlanCards
        user={this.props.user}
        uiConfig={this.props.uiConfig}
        firebaseAuth={this.props.firebaseAuth}
        verifyingLogin={this.props.verifyingLogin}
      />
    );

    if (this.props.user && this.props.user["plan"] == "pro") {
      maxPlanPages = process.env.REACT_APP_PRO_MAX_PAGES * 1;
      maxPlanTokens = process.env.REACT_APP_PRO_MAX_TOKENS * 1;
      plan = 'pro'
      planCards = <></>;
    }

    // Otherwise show them their user account data

    let header_data = null;
    if (!this.props.user) {
      header_data = <></>;
    } else {
      header_data = (
        <Modal.Header closeButton>
          <Row>
              <Col>
                <div>
                  <span style={{ fontSize: "175%" }}>
                    {this.props.user ? this.props.user.name : ""}
                  </span>
                  <span style={{ marginLeft: "15px" }}>
                    (<a href="#" onClick={this.props.logout}>logout</a>)
                  </span>
                </div>
                <small>{this.props.user ? this.props.user.email : ""}</small>
              </Col>
            </Row>
        </Modal.Header>
      );
    }

    let error_msg = "";
    let info = "";
    if (this.props.planError["plan_error"] == "max_tokens") {
      if (plan == "pro") {
        error_msg = <p>Your PDF is too long, even for a Pro account 😞</p>;
      } else {
        error_msg = <p>Upgrade to the Pro plan to summarize long PDFs.</p>;
      }
      info = (
        <ul>
          <li>
            Your PDF, <strong>{this.props.pdfFilename}</strong>, has{" "}
            <code>{formatNumber(this.props.planError["tokens"] * 1)} </code>{" "}
            tokens (about{" "}
            <code>{formatNumber(this.props.planError["tokens"] * 0.75)}</code>{" "}
            words)
          </li>
          <ul>
            <li>
              The max for a <code>{plan}</code> account is{" "}
              <code>{maxPlanTokens}</code> tokens
            </li>
          </ul>
        </ul>
      );
    } else {
      // max pages
      if (plan == "pro") {
        error_msg = <p>Your PDF is too long, even for a Pro account 😞</p>;
      } else {
        error_msg = <p>Upgrade to the Pro plan to summarize long PDFs.</p>;
      }
      info = (
        <ul>
          <li>
            Your PDF, <strong>{this.props.pdfFilename}</strong>, has{" "}
            <code>{this.props.pdfPages}</code> pages
          </li>
          <ul>
          <li>
              The max for a <code>{plan}</code> account is{" "}
              <code>{maxPlanPages}</code> pages
            </li>
          </ul>
        </ul>
      );
    }

    return (
      <div>
        {header_data}
        <Modal.Body>
          <Container>
            {error_msg}
            <Row>
              <Col>{info}</Col>
            </Row>
            {planCards}
          </Container>
        </Modal.Body>
      </div>
    );
  }
}

export default UpgradeModal;
