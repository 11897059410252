import React from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { StyledFirebaseAuth } from "./StyledFirebaseAuth";
import { getNumProPdfsUsed } from "./utils";

class PlanCards extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let free_bg = "#eeeeee";
    let pro_bg = "#ffffff";

    let free_button = null;
    let pro_button = null;

    let login_button = (
      <StyledFirebaseAuth
        uiConfig={this.props.uiConfig}
        firebaseAuth={this.props.firebaseAuth}
      />
    );

    if (this.props.verifyingLogin) {
      login_button = <Spinner animation="border" role="status" style={{ margin: "10px" }} />
    }

    const is_cancelled =
      this.props.user &&
      "cancel_at" in this.props.user &&
      this.props.user["cancel_at"] > 0;

    if (!this.props.user) {
      // free_button = (
      //   <Button
      //     variant="outline-secondary"
      //     disabled
      //     className="mt-auto"
      //     style={{ margin: "25px" }}
      //   >
      //     Current
      //   </Button>
      // );
      free_button = (
        <>
          <span style={{ marginTop: '-15px', marginLeft: "10px" }}>Login to get <code>{process.env.REACT_APP_FREE_PRO_PDF_COUNT}</code> free pro PDFs:</span>
          {login_button}
        </>
      );
      pro_button = (
        <>
          {/* <span style={{ marginTop: '-15px', marginLeft: "10px" }}>Login to upgrade:</span>
          {login_button} */}
        </>
      );
    } else if (
      !("plan" in this.props.user) ||
      this.props.user["plan"] == "free"
    ) {
      // Logged in but on free plan
      free_button = (
        <Button
          variant="outline-secondary"
          disabled
          className="mt-auto"
          style={{ margin: "25px" }}
        >
          Current
        </Button>
      );
      pro_button = (
        <Form
          action="/create-checkout-session"
          method="POST"
          className="mt-auto"
          style={{ margin: "25px" }}
        >
          <Button variant="primary" style={{ width: "100%" }} type="submit">
            Upgrade
          </Button>
          <input type="hidden" name="lookup_key" value="pdf2gpt_pro_plan" />
        </Form>
      );
    } else {
      // Logged in and pro!

      let cancel_or_renew_text = "";
      if ("cancel_at" in this.props.user && this.props.user["cancel_at"] > 0) {
        const date = new Date(this.props.user["cancel_at"] * 1000);
        // Format the date using the local timezone
        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        cancel_or_renew_text = (
          <span style={{ marginLeft: "10px", marginBottom: "10px" }}>
            Plan will not renew. Pro subscription will end on {formattedDate}.
          </span>
        );
      } else {
        const date = new Date(this.props.user["plan_end"] * 1000);
        // Format the date using the local timezone
        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        cancel_or_renew_text = (
          <span style={{ marginLeft: "10px", marginBottom: "10px" }}>
            Plan renews on: {formattedDate}
          </span>
        );
      }

      if (!is_cancelled) {
        free_button = (
          <Form
            action="/create-portal-session"
            method="POST"
            className="mt-auto"
            style={{ margin: "25px" }}
          >
            <Button
              variant="outline-danger"
              style={{ width: "100%" }}
              type="submit"
            >
              Cancel Auto-Renew
            </Button>
          </Form>
        );
        pro_button = (
          <>
            {cancel_or_renew_text}
            <Button
              variant="outline-secondary"
              disabled
              className="mt-auto"
              style={{ margin: "25px" }}
            >
              Already pro! 😀
            </Button>
          </>
        );
        free_bg = "#ffffff";
        pro_bg = "#eeeeee";
      } else {
        free_button = <></>;
        pro_button = (
          <>
            {cancel_or_renew_text}
            <Form
              action="/create-portal-session"
              method="POST"
              className="mt-auto"
              style={{ margin: "25px", paddingTop: "15px" }}
            >
              <Button variant="primary" style={{ width: "100%" }} type="submit">
                Resume plan
              </Button>
            </Form>
          </>
        );
        free_bg = "#ffffff";
        pro_bg = "#eeeeee";
      }
    }

    return (
      <>
        <Row>
          <Col xs={12} md={6}>
            <Card
              className="rounded d-flex flex-column h-100"
              style={{ backgroundColor: free_bg }}
            >
              <Card.Body>
                <Card.Title>Free</Card.Title>
                <Card.Text>
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#121212",
                      marginRight: "5px",
                    }}
                  >
                    $0
                  </span>
                  /mo
                </Card.Text>
                <ul>
                  <li>{process.env.REACT_APP_FREE_MAX_PAGES} pages/pdf</li>
                  <li>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          GPT measures text in "tokens" so our limit is{" "}
                          {(
                            process.env.REACT_APP_FREE_MAX_TOKENS * 1
                          ).toLocaleString("en-US", {
                            minimumIntegerDigits: 1,
                            useGrouping: true,
                          })}{" "}
                          tokens.
                          <br />
                          <br />
                          100 tokens is appoximagely 75 words.
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        style={{
                          textDecorationThickness: "1px",
                          textDecorationLine: "underline",
                          textDecorationStyle: "dashed",
                        }}
                      >
                        About{" "}
                      </span>
                    </OverlayTrigger>
                    {(
                      process.env.REACT_APP_FREE_MAX_TOKENS * 0.75
                    ).toLocaleString("en-US", {
                      minimumIntegerDigits: 1,
                      useGrouping: true,
                    })}{" "}
                    words/pdf
                  </li>
                  <li>40 MB / PDF (for uploads)</li>
                  <li><code>{process.env.REACT_APP_FREE_PRO_PDF_COUNT}</code> free long PDFs (<code>{getNumProPdfsUsed(this.props.user)}</code> used)</li>
                </ul>
              </Card.Body>
              {free_button}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card
              className="rounded d-flex flex-column h-100"
              style={{ backgroundColor: pro_bg }}
            >
              <Card.Body>
                <Card.Title>Pro</Card.Title>
                <Card.Text>
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#121212",
                      marginRight: "5px",
                    }}
                  >
                    $5
                  </span>
                  /mo
                </Card.Text>
                <ul>
                  <li>{process.env.REACT_APP_PRO_MAX_PAGES} pages/pdf</li>
                  <li>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          GPT measures text in "tokens" so our limit is{" "}
                          {(
                            process.env.REACT_APP_PRO_MAX_TOKENS * 1
                          ).toLocaleString("en-US", {
                            minimumIntegerDigits: 1,
                            useGrouping: true,
                          })}{" "}
                          tokens.
                          <br />
                          <br />
                          100 tokens is appoximagely 75 words.
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        style={{
                          textDecorationThickness: "1px",
                          textDecorationLine: "underline",
                          textDecorationStyle: "dashed",
                        }}
                      >
                        About{" "}
                      </span>
                    </OverlayTrigger>
                    {(
                      process.env.REACT_APP_PRO_MAX_TOKENS * 0.75
                    ).toLocaleString("en-US", {
                      minimumIntegerDigits: 1,
                      useGrouping: true,
                    })}{" "}
                    words/pdf
                  </li>
                  <li>40 MB / PDF (for uploads)</li>
                </ul>
                <hr />
                <p style={{marginTop:'2px'}}>Cancel anytime and keep Pro for the rest of the month</p>
              </Card.Body>
              {pro_button}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PlanCards;
