import React, { useState, useEffect, useMemo } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import axios from "axios";
import {
  faSnowplow,
  faCartFlatbedSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import { useDropzone } from "react-dropzone";
// Import the functions you need from the SDKs you need

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { StyledFirebaseAuth } from "./StyledFirebaseAuth";
import AccountModal from "./AccountModal";
import UpgradeModal from "./UpgradeModal";
import ShareURLDropdown from "./ShareURLDropdown";
import LanguageSelector from "./LanguageSelector";
import { getNumProPdfsUsed } from "./utils";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDP0D4gbNyJspoMGMRY5erZUH05-f5FIcI",
  authDomain: "pdf2gpt-fb.firebaseapp.com",
  projectId: "pdf2gpt-fb",
  storageBucket: "pdf2gpt-fb.appspot.com",
  messagingSenderId: "814985368310",
  appId: "1:814985368310:web:075e89bd7f211957d312d7",
  measurementId: "G-TBWQE83CNY",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  margin: "0px",
  borderWidth: 4,
  borderRadius: 2,
  borderColor: "#aaaaaa",
  borderStyle: "dashed",
  backgroundColor: "#eeeeee",
  color: "#333333",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {};

const acceptStyle = {
  borderColor: "#333333",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone(props) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop: props.onDrop, accept: { "application/pdf": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  let text = (
    <p>
      Drop PDF <br />
      (or click to browse)
    </p>
  );
  if (isDragReject) {
    text = (
      <p>
        only .pdf files supported
        <br />
      </p>
    );
  }

  return (
    <div
      className="container"
      style={{ border: "5px solid #eeeeee", padding: "0px", borderRadius: 6 }}
    >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {text}
      </div>
    </div>
  );
}

function App() {
  const [summaries, setSummaries] = React.useState({});
  const [hasStarted, setHasStarted] = React.useState(false);
  const [numSections, setNumSections] = React.useState(0);
  const [numSectionsPending, setNumSectionsPending] = React.useState(0);
  const [uploadTotal, setUploadTotal] = React.useState(0);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadDone, setUploadDone] = React.useState(true);
  const [serverSideDownload, setServerSideDownload] = React.useState(false);
  const [askQuestionSelected, setAskQuestionSelected] = React.useState(false);
  const [numPages, setNumPages] = React.useState(0);
  const [urlError, setUrlError] = React.useState("");
  const [user, setUser] = React.useState(null);
  const [showAccount, setShowAccount] = React.useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);
  const [signedOut, setSignedOut] = React.useState(0);
  const [planError, setPlanError] = React.useState("");
  const [currentPdf, setCurrentPdfHelper] = React.useState("");
  const [userAskedQuestion, setUserAskedQuestion] = React.useState(false);
  const [question, setQuestion] = React.useState("");
  const [verifyingLogin, setVerifyingLogin] = React.useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = React.useState(false);
  const [showSizeError, setShowSizeError] = React.useState(false);
  const [fileUploadSize, setFileUploadSize] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [shareUrl, setShareUrlHelper] = React.useState("");
  const [renderingShare, setRenderingShare] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    code: "US",
    emoji: "🇺🇸",
    language: "English",
    display: "English",
  });

  const questionRef = React.useRef();
  const questionRef2 = React.useRef();
  const questionRef3 = React.useRef();
  const pdfUrlRef = React.useRef();

  const loginHasRun = React.useRef(false);
  const requestId = React.useRef(0);

  // Attempt to login at start.
  React.useEffect(() => {
    if (loginHasRun.current) {
      return;
    }
    loginHasRun.current = true;

    // check for payment success
    let payment_session_id = "";
    console.log(window.location.pathname);
    if (window.location.pathname === "/success") {
      setShowPaymentSuccess(true); // show payment success toast
      const params = new URLSearchParams(window.location.search);
      payment_session_id = params.get("session_id");

      if (payment_session_id) {
        // params.delete('session_id')
        // var url = new URL(window.location.href);
        // url.search = params.toString()

        window.history.replaceState(null, "", window.location.origin);
      }

      // Check if there is a PDF to process
      const cPdf = window.localStorage.getItem("currentPdf");
      if (cPdf && cPdf.length > 0) {
        login(payment_session_id, reprocessPdf);
      } else {
        login(payment_session_id);
      }
    } else {
      login(payment_session_id);
    }

    // Check for loading a shared session
    const params = new URLSearchParams(window.location.search);
    const summary = params.get("summary");

    if (summary !== null) {
      console.log("Loading summary");

      // Load the summary from the backedn
      fetch("loadSummary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ summary: summary }),
      })
        .then((res) =>
          res.json().then((result) => {
            console.log(result);

            // Unpack the share data into the various variables.

            // PDF name
            setCurrentPdf(result["name"]);

            // Unpack pages.
            setSummaries(result);
            setNumSectionsPending(0);
            setNumSections(Object.keys(result).length);
            setRenderingShare(true);
            setHasStarted(true);
            if (result["question"] != null && result["question"].length > 0) {
              setUserAskedQuestion(true);
              setQuestion(result["question"]);
            }
          })
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: signInSuccessWithAuthResult,
    },
  };

  let sum_out = [];
  if (numSections > 1) {
    for (let i in summaries) {
      if (i < 0 || typeof summaries[i] !== "object" || summaries[i] === null) {
        continue;
      }
      sum_out.push(
        <SummaryDisplay section_num={i} data={summaries[i]} key={i} />
      );
    }
  }

  let spinnerDisp = "";
  if (numSectionsPending > 0 || !uploadDone) {
    spinnerDisp = "none";
  }

  let masterSummaryDisp = "none";
  if (hasStarted) {
    masterSummaryDisp = "";
  }

  let sectionsPending = <></>;
  let pages = numPages < 2 ? "page" : "pages";
  if (numSectionsPending > 0 && numSections > 1) {
    sectionsPending = (
      <div>
        Full summary: pending summarization of {numPages} {pages} in{" "}
        {numSections} parts ({numSectionsPending} to go)
      </div>
    );
  }

  let uploadProgressBar = <></>;
  const now = 100 * (uploadProgress / uploadTotal);
  if (!uploadDone && uploadTotal > 0 && uploadProgress < uploadTotal) {
    if (serverSideDownload) {
      uploadProgressBar = (
        <div>
          <h4>Getting PDF</h4>
          <ProgressBar now={now} style={{ marginTop: "8px" }} />
          <br />
        </div>
      );
    } else {
      uploadProgressBar = (
        <div>
          Uploading: <ProgressBar now={now} style={{ marginTop: "8px" }} />
          <br />
        </div>
      );
    }
  } else if (!uploadDone && serverSideDownload) {
    // download is on the server and is connecting...
    uploadProgressBar = (
      <div>
        <h4>Getting PDF</h4>
        <Spinner animation="border" role="status" style={{ margin: "10px" }} />
        <br />
      </div>
    );
    masterSummaryDisp = "none";
  }

  const masthead_css = hasStarted ? "" : "masthead";

  function setCurrentPdf(pdf_file) {
    window.localStorage.setItem("currentPdf", pdf_file);
    setCurrentPdfHelper(pdf_file);
  }

  function getQuestionRef() {
    if (hasStarted) {
      return questionRef3;
    }
    if (questionRef.current.offsetParent != null) {
      return questionRef;
    }
    return questionRef2;
  }

  function login(payment_session_id = "", callback = null) {
    fetch("login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_session_id: payment_session_id,
      }),
    })
      .then((res) =>
        res.json().then((result) => {
          setUser(result["user"]);
          setShowAccount(false);
          setShowUpgradeModal(false);
          console.log(callback);

          if (callback) {
            callback();
          }
        })
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function logout() {
    fetch("/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((result) => {
          console.log(result);
          setShowUpgradeModal(false);
          setShowAccount(false);
          setUser(null);

          // This is a hack to deal with the Google login UI not re-appearing after logout
          setTimeout(() => {
            window.location.reload();
          }, 200);
        })
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getPlan() {
    if (user && "plan" in user && user["plan"].length > 0) {
      return user["plan"];
    }
    return "free";
  }

  let mainSummary = <></>;
  if (summaries !== undefined && -1 in summaries) {
    mainSummary = summaries[-1]["output"];
    spinnerDisp = "none";
  }

  const tableOfContents = generateTableOfContents();

  let user_login = null;

  if (user) {
    let user_plan_text = <span> ({getPlan()} account)</span>;
    user_login = (
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <a
          href="#"
          onClick={(e) => {
            setShowAccount(true);
          }}
        >
          <img
            src={user.photoURL}
            style={{ width: 30, borderRadius: 50, marginRight: "10px" }}
          />
          {user.name}
        </a>
        {user_plan_text}
      </div>
    );
  } else {
    user_login = (
      <div style={{ textAlign: "right" }}>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          signedOut={signedOut}
          key={signedOut}
        />
      </div>
    );
  }

  let errorMessageToast = <></>;
  if (errorMessage) {
    errorMessageToast = (
      <ToastContainer position="bottom-center">
        <Toast
          show={errorMessage != null}
          delay={7000}
          onClose={() => setErrorMessage(null)}
          autohide
          style={{ marginBottom: "10px", backgroundColor: "#dddddd" }}
        >
          <Toast.Body style={{ textAlign: "center" }}>
            {errorMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );
  }

  let shareButtonVisibility =
    shareUrl && shareUrl.length > 0 ? "visible" : "hidden";
  const askQuestionDisp = hasStarted && !renderingShare ? "" : "none";

  let buttonsRunning = <></>;
  let languageSelect = (
    <div style={{ marginTop: "4px", marginBottom: "4px" }}>
      <LanguageSelector
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
    </div>
  );
  if (hasStarted) {
    buttonsRunning = (
      <>
        <div style={{ display: "flex" }}>
          <span
            style={{ marginTop: "10px", flexGrow: 1 }}
            className="only-show-widescreen"
          >
            pdf &rarr; gpt - Summarize a PDF
          </span>
          <Button
            variant="outline-primary"
            style={{ marginLeft: "20px", marginTop: "4px" }}
            onClick={(e) => {
              setHasStarted(false);
              setShareUrl("");
              setRenderingShare(false);
            }}
          >
            New PDF
          </Button>
          <div style={{ visibility: shareButtonVisibility }}>
            <ShareURLDropdown />
          </div>
        </div>
      </>
    );
    languageSelect = (
      <span>
        {selectedLanguage.emoji} {selectedLanguage.display}
      </span>
    );
  }

  let with_book = <div style={{display: 'flex'}}>{languageSelect}
  <span style={{margin: '10px'}}>I really enjoyed this book, <a target="_blank" href="https://www.amazon.com/Dear-Oliver-Unexpected-Friendship-Sacks/dp/1891011308/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1706820828&sr=8-1">Dear Oliver</a>, maybe you'll like it.</span>
  </div>

  if (isMobile || hasStarted) {
    with_book = <>{languageSelect}</>
  }

  return (
    <>
      <nav
        className="navbar navbar-light bg-light static-top"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <div className="container">
          {buttonsRunning}
          {with_book}
          {user_login}
        </div>
      </nav>

      <header
        className={masthead_css + " text-white text-center"}
        style={{ paddingTop: "2.5em", display: hasStarted ? "none" : "" }}
      >
        <Modal
          show={showAccount}
          onHide={(e) => setShowAccount(false)}
          centered
        >
          <AccountModal
            title="Account"
            user={user}
            logout={logout}
            onClose={() => setShowAccount(false)}
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
            verifyingLogin={verifyingLogin}
          />
        </Modal>

        <Modal
          show={showSizeError}
          onHide={(e) => setShowSizeError(false)}
          centered
        >
          <Modal.Header closeButton>
            <h3>File too large</h3>
          </Modal.Header>
          <Modal.Body>
            The maximum size for uploaded PDFs is <code>40 MB</code>.<br />
            Your file is: <code>{fileUploadSize} MB</code>
          </Modal.Body>
        </Modal>

        <Modal
          show={showUpgradeModal}
          onHide={(e) => setShowUpgradeModal(false)}
          centered
        >
          <UpgradeModal
            title="Account"
            user={user}
            logout={logout}
            onClose={() => setShowUpgradeModal(false)}
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
            pdfFilename={currentPdf}
            pdfPages={numPages}
            planError={planError}
            verifyingLogin={verifyingLogin}
          />
        </Modal>
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <h3 className="mb-5" id="header_text">
                {hasStarted ? "" : <>pdf &rarr; gpt - Summarize a PDF</>}
              </h3>
            </div>
            <div className="mx-auto">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="row"
              >
                <div
                  style={{
                    fontSize: "1.5em",
                    marginRight: "10px",
                    marginBottom: "10px",
                    color: hasStarted ? "black" : "inherit",
                  }}
                  className="col-md-6"
                >
                  I want to
                  <ButtonGroup vertical style={{ margin: "10px" }}>
                    <Button
                      variant={
                        askQuestionSelected ? "outline-primary" : "primary"
                      }
                      className={
                        askQuestionSelected
                          ? "question-not-selected"
                          : "question-selected"
                      }
                      onClick={(e) => setAskQuestionSelected(false)}
                    >
                      summarize
                    </Button>
                    <Button
                      variant={
                        askQuestionSelected ? "primary" : "outline-primary"
                      }
                      className={
                        askQuestionSelected
                          ? "question-selected"
                          : "question-not-selected"
                      }
                      onClick={(e) => {
                        setAskQuestionSelected(true);
                        setTimeout(() => {
                          getQuestionRef().current.focus();
                          getQuestionRef().current.select();
                        }, 20);
                      }}
                    >
                      ask a specific question about
                    </Button>
                  </ButtonGroup>
                  a PDF
                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "1.2em",
                      display: askQuestionSelected ? "" : "none",
                      color: hasStarted ? "black" : "inherit",
                    }}
                    className="question-box-narrow-page"
                  >
                    <hr />
                    Question:
                    <Form.Control
                      ref={questionRef2}
                      placeholder="ex: does this mention penguins?"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <hr />
                  </div>
                </div>
                <div className="col">
                  <StyledDropzone onDrop={onSubmit} />
                  <div style={{ marginTop: "5px" }}>or paste a URL:</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ flex: "1 1 auto" }}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          onGoClick(e);
                        }}
                      >
                        <Form.Control
                          ref={pdfUrlRef}
                          isInvalid={urlError.length > 0}
                          placeholder="https://example.com/file.pdf"
                        />
                      </Form>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                      <Button style={{ margin: "5px" }} onClick={onGoClick}>
                        Go
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "1.2em",
                  visibility: askQuestionSelected ? "" : "hidden",
                  width: "50%",
                  color: hasStarted ? "black" : "inherit",
                }}
                className="question-box-normal-page"
              >
                Question:
                <Form.Control
                  ref={questionRef}
                  placeholder="ex: does this mention penguins?"
                  style={{ marginRight: "20px", marginTop: "5px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Section that is shown after a PDF has been processed */}

      <section style={{ display: askQuestionDisp }}>
        <div className="container">
          <div className="row">
            <div>
              <hr />
              <h4>
                {userAskedQuestion
                  ? "Ask another question about "
                  : "Also ask a question about "}
                <code>{currentPdf}</code>
              </h4>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  reprocessPdf();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ flex: "1 1 auto" }}>
                    <div>
                      <Form.Control
                        ref={questionRef3}
                        placeholder="ex: does this mention penguins?"
                        style={{ marginRight: "20px" }}
                      />
                    </div>
                  </div>
                  <div style={{ flex: "0 0 auto" }}>
                    <Button style={{ margin: "5px" }} type="submit">
                      Go
                    </Button>
                  </div>
                </div>
              </Form>
              <hr />
            </div>
          </div>
        </div>
      </section>

      <section style={{ margin: "20px" }}>
        {uploadProgressBar}
        <div style={{ display: masterSummaryDisp }}>
          <h4>
            {userAskedQuestion ? 'Answer to "' + question + '"' : "Summary"}
            {renderingShare ? (
              <>
                {" "}
                - <code>{currentPdf}</code>
              </>
            ) : (
              ""
            )}
          </h4>
          <div style={{ display: spinnerDisp }}>
            <Spinner
              animation="border"
              role="status"
              style={{ margin: "10px" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          {sectionsPending}
          <div style={{ marginTop: "15px", whiteSpace: "pre-wrap" }}>
            {mainSummary}
          </div>
          <div>{tableOfContents}</div>
        </div>
        {sum_out}
      </section>
      {/* <br /><br />
      <hr />
      <ul style={{marginLeft: '20px'}}>
        <li>Summaries now include a bulleted list</li>
        <li>Let me know if that is working for you: <a href="mailto:pdf2gpt@gmail.com">pdf2gpt@gmail.com</a></li>
      </ul> */}
      <hr />
      <section
        className="features-icons bg-light text-center"
        style={{ paddingBottom: "2em" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon">
                  <FontAwesomeIcon
                    icon={faCartFlatbedSuitcase}
                    className="features-icons-icon text-primary"
                  />
                </div>
                <h3>huge PDFs supported</h3>
                <p className="lead mb-0">
                  automatically breaks PDFs into chunks so it fits into the GPT
                  context limit
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon">
                  <FontAwesomeIcon
                    icon={faSnowplow}
                    className="features-icons-icon text-primary"
                  />
                </div>
                <h3>on the GPT hype train</h3>
                <p className="lead mb-0">choo choo</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                <div className="features-icons-icon">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="features-icons-icon text-primary"
                  />
                </div>
                <h3>fast</h3>
                <p className="lead mb-0">
                  upload your file right away, no account needed for smaller
                  files.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <div className="container" style={{}}>
            <div className="row">
              <div style={{ textAlign: "left" }} className="col-lg-6"></div>
              <div style={{ textAlign: "right" }} className="col-lg-6">
                Contact me at:{" "}
                <a href="mailto:pdf2gpt@gmail.com">pdf2gpt@gmail.com</a>
                <br />
                <span style={{ fontSize: "small", textAlign: "left" }}>
                  I'm sorry I can't keep pdf2gpt.com free; the OpenAI bill is
                  piling up
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-center">
        <Toast
          show={showPaymentSuccess}
          delay={3000}
          onClose={() => setShowPaymentSuccess(false)}
          autohide
          style={{ marginBottom: "10px", backgroundColor: "#dddddd" }}
        >
          <Toast.Body style={{ textAlign: "center" }}>
            Subscription successful
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {errorMessageToast}
    </>
  );

  function signInSuccessWithAuthResult(authResult, redirectUrl) {
    console.log("signInSuccess");
    console.log(authResult);

    // Get the Firebase ID token
    authResult.user
      .getIdToken()
      .then((idToken) => {
        setVerifyingLogin(true);

        fetch("verifyToken", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
        })
          .then((res) =>
            res.json().then((result) => {
              console.log(result);
              setUser(result["user"]);
              // setShowAccount(false);
              // setShowUpgradeModal(false);

              // if the user just logged in, check if they are pro and if there was an error on the plan
              // if so, reprocess.
              if (
                planError != "" &&
                (result["user"]["plan"] == "pro" ||
                  getNumProPdfsUsed(result["user"]) <
                    process.env.REACT_APP_FREE_PRO_PDF_COUNT)
              ) {
                console.log(
                  "Reprocessing PDF after login when there was a plan error and the user is pro."
                );
                setShowUpgradeModal(false);
                setShowAccount(false);
                setVerifyingLogin(false);
                reprocessPdf();
              }
            })
          )
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.error("Error getting ID token:", error);
      });

    return false;
  }

  function getPages(data) {
    let page_start = parseInt(data["page_data"][0]);
    let page_end = parseInt(data["page_data"][1]);
    let pages = page_end - page_start > 0 ? "Pages" : "Page";

    return (
      <span>
        {pages} {page_start + 1}&mdash;{page_end + 1}
      </span>
    );
  }

  function SummaryDisplay(props) {
    let page_start = parseInt(props.data["page_data"][0]);
    let page_end = parseInt(props.data["page_data"][1]);
    let pages = page_end - page_start > 0 ? "Pages" : "Page";

    let page_section_num = parseInt(props.data["page_part"]);

    let section = page_section_num != 0 ? "." + page_section_num + 2 : "";

    let toc_line = <></>;
    if ("toc" in props.data) {
      toc_line = (
        <span style={{ fontVariant: "small-caps" }}>: {props.data["toc"]}</span>
      );
    }

    return (
      <div style={{ marginTop: "20px" }}>
        <hr />
        <h4 id={props.section_num}>
          {getPages(props.data)}
          {toc_line}
        </h4>
        <div style={{ marginTop: "15px", whiteSpace: "pre-wrap" }}>
          {props.data["output"]}
        </div>
      </div>
    );
  }

  function getSelectedLanguage() {
    if ("language" in selectedLanguage) {
      return selectedLanguage.language;
    }
    return null;
  }

  function setShareUrl(url) {
    setShareUrlHelper(url);

    if (url == "") {
      url = window.location.origin.toString();
    }
    // update the page's URL without a redirect
    window.history.pushState(null, null, url);
  }

  function generateTableOfContents() {
    let toc = [];
    if (numSections > 1) {
      for (let i in summaries) {
        if (
          i < 0 ||
          typeof summaries[i] !== "object" ||
          summaries[i] === null
        ) {
          continue;
        }
        let toc_line = (
          <span>
            : <Spinner size="sm" variant="secondary" />
          </span>
        );
        if ("toc" in summaries[i]) {
          toc_line = <span>: {summaries[i]["toc"]}</span>;
        }

        toc.push(
          <li key={i}>
            <a href={"#" + i}>{getPages(summaries[i])}</a>
            {toc_line}
          </li>
        );
      }
    }
    if (toc.length > 0) {
      return (
        <>
          <br />
          <strong>Contents</strong>
          <ul>{toc}</ul>
        </>
      );
    } else {
      return <></>;
    }
  }

  function handleUploadProgress(e) {
    setUploadProgress(e.loaded);
    setUploadTotal(e.total);
  }

  function onGoClick(e) {
    // Determine if the URL box has something in it

    let pdf_url = pdfUrlRef.current.value;
    if (pdf_url.length < 1) {
      setUrlError("Enter a URL for a PDF");
    } else {
      setUrlError("");
    }

    console.log(urlError);

    processPDF(true, e);
  }

  function reprocessPdf() {
    console.log("reprocess!");
    let question = getQuestionRef().current.value;
    requestId.current += 1;

    let fetch_data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      payload: JSON.stringify({
        pdf_filename: currentPdf,
        question: question,
        requestId: requestId.current,
        language: getSelectedLanguage(),
      }),
    };

    setUserAskedQuestion(question != "");
    setQuestion(question);
    setHasStarted(true);

    doPdfProcessing("reprocess_pdf", fetch_data);
  }

  function onSubmit(e) {
    processPDF(false, e);
  }

  function processPDF(useUrl, e) {
    console.log("on submit");
    setUploadDone(false);
    setHasStarted(true);
    setNumSections(0);
    setNumSectionsPending(0);
    setSummaries({});
    requestId.current += 1;

    if (!isMobile) {
      setTimeout(() => {
        questionRef3.current.focus();
      }, 200);
    }

    let question = getQuestionRef().current.value;

    if (!askQuestionSelected) {
      question = "";
    }

    setUserAskedQuestion(question != "");
    setQuestion(question);

    let pdf_url = pdfUrlRef.current.value;
    console.log(pdf_url);

    // let model = gpt4 ? "gpt-4" : "gpt-3.5-turbo";
    let fetch_data = null;
    if (useUrl) {
      setServerSideDownload(true);
      let url = null;
      try {
        url = new URL(pdf_url);
      } catch (error) {
        try {
          url = new URL("http://" + pdf_url);
        } catch (error) {
          setErrorMessage(<span>Invalid URL</span>);
          setUploadDone(true);
          setHasStarted(false);
          setShareUrl("");
        }
      }
      const filename = url.pathname.substring(
        url.pathname.lastIndexOf("/") + 1
      );
      console.log(filename);
      setCurrentPdf(filename);
      fetch_data = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        payload: JSON.stringify({
          pdf_url: url.toString(),
          pdf_filename: filename,
          question: question,
          requestId: requestId.current,
          language: getSelectedLanguage(),
        }),
      };
      doPdfProcessing("process_pdf", fetch_data);
    } else {
      setServerSideDownload(false);
      const data = new FormData();
      data.append("file", e[0]);

      // Check file size
      // Max size supported by the server is 40MB
      const max_size = 40000000;

      if (e[0].size) {
        setFileUploadSize(Math.round(e[0].size / 1000000));
        if (e[0].size > max_size) {
          setShowSizeError(true);
          return;
        }
      }

      setCurrentPdf(e[0].name);

      // Ask the server for a google cloud storage URL
      fetch("generate_upload_url", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) =>
          res.json().then((result) => {
            console.log(result);
            const gcloud_upload_url = result["url"];
            const blob_name = result["blob_name"];

            // Start upload with that url
            axios
              .put(gcloud_upload_url, e[0], {
                headers: {
                  "Content-Type": e[0].type || "application/pdf", // default to 'application/pdf' if the file type is not set
                },
                onUploadProgress: handleUploadProgress,
              })
              .then((data) => {
                setUploadDone(true);

                fetch_data = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  payload: JSON.stringify({
                    blob_name: blob_name,
                    pdf_filename: e[0].name,
                    question: question,
                    requestId: requestId.current,
                    language: getSelectedLanguage(),
                  }),
                };
                doPdfProcessing("process_pdf", fetch_data);
              });
          })
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function handleStreamError(e) {
    setUploadDone(true);
    setHasStarted(false);
    setShareUrl("");

    console.log("stream error");
    console.log(e);
    setErrorMessage(
      <span>
        An error occurred. If this keeps happening, please email me at
        pdf2gpt@gmail.com with the date & time and I will try to fix it.
      </span>
    );
  }

  function doPdfProcessing(endpoint, fetch_data) {
    var source = new SSE(endpoint, fetch_data, (e) => handleUploadProgress(e));
    setPlanError("");
    setSummaries({});
    setNumSections(0);
    setNumSectionsPending(0);

    let sum_output = {};
    let all_out = "";
    source.addEventListener("error", handleStreamError);
    source.addEventListener("abort", handleStreamError);

    source.addEventListener("message", function (e) {
      // Assuming we receive JSON-encoded data payloads:
      if (e.data != "[DONE]") {
        let result = JSON.parse(e.data);

        console.log(result);

        if (
          !("request_id" in result) ||
          result["request_id"] != requestId.current
        ) {
          return;
        }

        if ("num_pages" in result) {
          setNumPages(result.num_pages);
          setUploadDone(true);
          setUploadProgress(0); // so that if they upload another file the progress bar will start at 0
        }

        if ("share_id" in result) {
          const url =
            window.location.origin.toString() + "/?summary=" + result.share_id;
          setShareUrl(url);
        }

        if ("num_sections" in result) {
          setNumSections(result.num_sections);
          setNumSectionsPending(result.num_sections);
        }

        // Check for server-side PDF download progress
        if ("download_bytes" in result) {
          setUploadProgress(result["download_bytes"]);
          setUploadTotal(result["download_total"]);
          setServerSideDownload(true);
        }

        if ("download_done" in result) {
          setUploadDone(true);
        }

        if ("user" in result) {
          setUser(result["user"]);
        }

        let page_data = result.page;
        let gpt_data = result.data;

        let page_part = result.page_part;
        let thread_index = result.thread_index;

        if ("plan_error" in result) {
          setUploadDone(true);
          setNumPages(result["pages"]);
          setPlanError(result);
          setShowUpgradeModal(true);
          setHasStarted(false);
          setShareUrl("");
        }

        if ("pdf_error" in result) {
          setErrorMessage(
            <span>
              Unable to read PDF
              <br />
              <br />
              Maybe you linked to a website instead of directly to the PDF?
            </span>
          );
          setUploadDone(true);
          setHasStarted(false);
          setShareUrl("");
        }

        if (
          "toc" in result &&
          result.toc &&
          result.toc.choices &&
          result.toc.choices.length > 0 &&
          result.toc.choices[0].message &&
          result.toc.choices[0].message.content
        ) {
          // This is a table-of-contents header, not a summary message.
          let thread_index = result.thread_index;
          const toc = result.toc.choices[0].message.content;

          if (sum_output[thread_index] === undefined) {
            sum_output[thread_index] = {
              output: "",
              page_data: page_data,
              page_part: page_part,
              toc: toc,
            };
          } else {
            sum_output[thread_index]["toc"] = toc;
          }
          setSummaries({ ...sum_output });
        }

        if ("num_sections_pending" in result) {
          console.log("SET NUM SECTIONS PENDING" + result.num_sections_pending);
          setNumSectionsPending(result.num_sections_pending);
        }
        // console.log(result);

        if (
          gpt_data &&
          gpt_data.choices &&
          gpt_data.choices.length > 0 &&
          gpt_data.choices[0].delta &&
          gpt_data.choices[0].delta.content
        ) {
          const output = gpt_data.choices[0].delta.content;
          //all_out += '[' + section_num + ']:' + output;
          if (sum_output[thread_index] === undefined) {
            sum_output[thread_index] = {
              output: output,
              page_data: page_data,
              page_part: page_part,
            };
          } else {
            sum_output[thread_index] = {
              output: sum_output[thread_index]["output"] + output,
              page_data: page_data,
              page_part: page_part,
            };
          }
          setSummaries({ ...sum_output });
          // } else if (
          //   gpt_data &&
          //   gpt_data.choices &&
          //   gpt_data.choices.length > 0 &&
          //   gpt_data.choices[0].finish_reason &&
          //   gpt_data.choices[0].finish_reason.length > 0
          // ) {
          //   setNumSectionsPending(numSectionsPending - 1);
        } else if (gpt_data && !gpt_data.choices) {
          all_out += "\n\nError: " + gpt_data;
        }
      }
    });

    source.stream();
  }
}
export default App;

var SSE = function (url, options, uploadProgressCallback) {
  if (!(this instanceof SSE)) {
    return new SSE(url, options);
  }

  this.INITIALIZING = -1;
  this.CONNECTING = 0;
  this.OPEN = 1;
  this.CLOSED = 2;

  this.url = url;

  options = options || {};
  this.headers = options.headers || {};
  this.payload = options.payload !== undefined ? options.payload : "";
  this.method = options.method || (this.payload && "POST") || "GET";
  this.withCredentials = !!options.withCredentials;

  this.FIELD_SEPARATOR = ":";
  this.listeners = {};

  this.xhr = null;
  this.readyState = this.INITIALIZING;
  this.progress = 0;
  this.chunk = "";

  this.addEventListener = function (type, listener) {
    if (this.listeners[type] === undefined) {
      this.listeners[type] = [];
    }

    if (this.listeners[type].indexOf(listener) === -1) {
      this.listeners[type].push(listener);
    }
  };

  this.removeEventListener = function (type, listener) {
    if (this.listeners[type] === undefined) {
      return;
    }

    var filtered = [];
    this.listeners[type].forEach(function (element) {
      if (element !== listener) {
        filtered.push(element);
      }
    });
    if (filtered.length === 0) {
      delete this.listeners[type];
    } else {
      this.listeners[type] = filtered;
    }
  };

  this.dispatchEvent = function (e) {
    if (!e) {
      return true;
    }

    e.source = this;

    var onHandler = "on" + e.type;
    if (this.hasOwnProperty(onHandler)) {
      this[onHandler].call(this, e);
      if (e.defaultPrevented) {
        return false;
      }
    }

    if (this.listeners[e.type]) {
      return this.listeners[e.type].every(function (callback) {
        callback(e);
        return !e.defaultPrevented;
      });
    }

    return true;
  };

  this._setReadyState = function (state) {
    var event = new CustomEvent("readystatechange");
    event.readyState = state;
    this.readyState = state;
    this.dispatchEvent(event);
  };

  this._onStreamFailure = function (e) {
    var event = new CustomEvent("error");
    event.data = e.currentTarget.response;
    this.dispatchEvent(event);
    this.close();
  };

  this._onStreamAbort = function (e) {
    this.dispatchEvent(new CustomEvent("abort"));
    this.close();
  };

  this._onStreamProgress = function (e) {
    if (!this.xhr) {
      return;
    }

    if (this.xhr.status !== 200) {
      this._onStreamFailure(e);
      return;
    }

    if (this.readyState == this.CONNECTING) {
      this.dispatchEvent(new CustomEvent("open"));
      this._setReadyState(this.OPEN);
    }

    var data = this.xhr.responseText.substring(this.progress);
    this.progress += data.length;
    data.split(/(\r\n|\r|\n){2}/g).forEach(
      function (part) {
        if (part.trim().length === 0) {
          this.dispatchEvent(this._parseEventChunk(this.chunk.trim()));
          this.chunk = "";
        } else {
          this.chunk += part;
        }
      }.bind(this)
    );
  };

  this._onStreamLoaded = function (e) {
    this._onStreamProgress(e);

    // Parse the last chunk.
    this.dispatchEvent(this._parseEventChunk(this.chunk));
    this.chunk = "";
  };

  /**
   * Parse a received SSE event chunk into a constructed event object.
   */
  this._parseEventChunk = function (chunk) {
    if (!chunk || chunk.length === 0) {
      return null;
    }

    var e = { id: null, retry: null, data: "", event: "message" };
    chunk.split(/\n|\r\n|\r/).forEach(
      function (line) {
        line = line.trimRight();
        var index = line.indexOf(this.FIELD_SEPARATOR);
        if (index <= 0) {
          // Line was either empty, or started with a separator and is a comment.
          // Either way, ignore.
          return;
        }

        var field = line.substring(0, index);
        if (!(field in e)) {
          return;
        }

        var value = line.substring(index + 1).trimLeft();
        if (field === "data") {
          e[field] += value;
        } else {
          e[field] = value;
        }
      }.bind(this)
    );

    var event = new CustomEvent(e.event);
    event.data = e.data;
    event.id = e.id;
    return event;
  };

  this._checkStreamClosed = function () {
    if (!this.xhr) {
      return;
    }

    if (this.xhr.readyState === XMLHttpRequest.DONE) {
      this._setReadyState(this.CLOSED);
    }
  };

  this.stream = function () {
    this._setReadyState(this.CONNECTING);

    this.xhr = new XMLHttpRequest();
    this.xhr.upload.onprogress = function (e) {
      uploadProgressCallback(e);
    };
    this.xhr.addEventListener("progress", this._onStreamProgress.bind(this));
    this.xhr.addEventListener("load", this._onStreamLoaded.bind(this));
    this.xhr.addEventListener(
      "readystatechange",
      this._checkStreamClosed.bind(this)
    );
    this.xhr.addEventListener("error", this._onStreamFailure.bind(this));
    this.xhr.addEventListener("abort", this._onStreamAbort.bind(this));
    this.xhr.open(this.method, this.url);
    for (var header in this.headers) {
      this.xhr.setRequestHeader(header, this.headers[header]);
    }
    this.xhr.withCredentials = this.withCredentials;
    this.xhr.send(this.payload);
  };

  this.close = function () {
    if (this.readyState === this.CLOSED) {
      return;
    }

    this.xhr.abort();
    this.xhr = null;
    this._setReadyState(this.CLOSED);
  };
};

// Export our SSE module for npm.js
if (typeof exports !== "undefined") {
  exports.SSE = SSE;
}
