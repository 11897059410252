import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import emojiFlags from "emoji-flags";

const LanguageSelector = (props) => {
  const languageOptions = [
    { code: "us", language: "English", display: "English" },
    { code: "in", language: "Hindi", display: "हिन्दी" },
    { code: "es", language: "Spanish", display: "Español" },
    { code: "ar", language: "Arabic", display: "العربية" },
    { code: "bd", language: "Bengali", display: "বাংলা" },
    { code: "cn", language: "Chinese", display: "中文" },
    { code: "cz", language: "Czech", display: "Čeština" },
    { code: "nl", language: "Dutch", display: "Nederlands" },
    { code: "fr", language: "French", display: "Français" },
    { code: "de", language: "German", display: "Deutsch" },
    { code: "gr", language: "Greek", display: "Ελληνικά" },
    { code: "it", language: "Italian", display: "Italiano" },
    { code: "jp", language: "Japanese", display: "日本語" },
    { code: "kr", language: "Korean", display: "한국어" },
    { code: "ir", language: "Persian", display: "فارسی" },
    { code: "pl", language: "Polish", display: "Polski" },
    { code: "pt", language: "Portuguese", display: "Português" },
    { code: "ro", language: "Romanian", display: "Română" },
    { code: "ru", language: "Russian", display: "Русский" },
    { code: "ke", language: "Swahili", display: "Kiswahili" },
    { code: "th", language: "Thai", display: "ไทย" },
    { code: "tr", language: "Turkish", display: "Türkçe" },
    { code: "pk", language: "Urdu", display: "اردو" },
    { code: "vn", language: "Vietnamese", display: "Tiếng Việt" },
    { code: "za", language: "Zulu", display: "isiZulu" },
  ].map((langOption) => ({
    ...langOption,
    emoji: emojiFlags.countryCode(langOption.code).emoji,
  }));

  const handleSelect = (eventKey) => {
    localStorage.setItem("language", eventKey);
    const lang = languageOptions.find((option) => option.language === eventKey);
    props.setSelectedLanguage(lang);
  };

  React.useEffect(() => {
    const initialValue = localStorage.getItem("language");
    if (initialValue) {
      handleSelect(initialValue);
    }
  }, []);

  return (
    <DropdownButton
      variant="outline-primary"
      id="dropdown-language-selector"
      title={
        <span>
          {props.selectedLanguage.emoji} {props.selectedLanguage.display}
        </span>
      }
      onSelect={handleSelect}
    >
      {languageOptions.map((option, index) => (
        <Dropdown.Item key={index} eventKey={option.language}>
          {option.emoji} {option.display}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default LanguageSelector;
