import React, { useState, useRef } from 'react';
import { Button, Dropdown, FormControl } from 'react-bootstrap';

const ShareURLDropdown = ({ url = window.location.href }) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy URL');
  const urlInputRef = useRef(null);

  const copyToClipboard = () => {
    if (urlInputRef.current) {
      urlInputRef.current.select();
      navigator.clipboard.writeText(url);

      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('Copy URL'), 1000);
    }
  };

  const handleInputClick = () => {
    if (urlInputRef.current) {
      urlInputRef.current.select();
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" id="share" style={{ marginLeft: "20px", marginTop: "4px", marginRight: "4px"}} >
        Share summary
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', width: '300px' }}>
          <FormControl
            as="input"
            ref={urlInputRef}
            value={url}  // using the prop value
            readOnly
            onClick={handleInputClick}
            style={{ flex: 1, marginRight: '0.5rem' }}
          />
          <Button variant="outline-secondary" onClick={copyToClipboard}>
            {copyButtonText}
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareURLDropdown;
